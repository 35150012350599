import "core-js/modules/es.array.push.js";
import { hasAuth } from '@/utils/auth';
import { defineComponent, defineAsyncComponent, computed, reactive, toRefs } from 'vue';
import ScrollText from '@/components/ScrollText';
import API from "@/api/live";
import { useDebounce } from "@/hooks/core/useDebounce";
export default defineComponent({
  name: 'LiveInfoWindow',
  components: {
    ScrollText,
    List: defineAsyncComponent(() => import('@/components/List')),
    AddressLabel: defineAsyncComponent(() => import('@/components/AddressLabel')),
    TypeSelect: defineAsyncComponent(() => import('@/components/TypeSelect/TypeSelect.vue')),
    BatteryIcon: defineAsyncComponent(() => import('@/components/BatteryIcon'))
  },
  emits: ['action:gj', 'action:mpttDetail', 'action:detail', 'action:dm', 'action:RF', 'action:cmd', 'action:onPanorama'],
  props: {
    info: {
      type: Object,
      default: null
    },
    isEmbed: {
      type: Boolean,
      default: false
    }
  },
  setup(props, {
    emit
  }) {
    const refData = reactive({
      loading: true,
      actions: [],
      fenceList: [],
      titleList: ['默认模式', '定时模式', '智能模式', '省电模式', '休眠飞行模式', '点名模式', '运动模式']
    });
    const contents = computed(() => !props.info ? [] : [{
      label: 'IMEI号',
      content: props.info.mdtid,
      visible: !!props.info.mdtid
    }, {
      label: '定位方式',
      content: `定位（${props.info.posTypeStr}）`,
      visible: props.info.posTypeStr ? true : false
    }, {
      label: '数据时间',
      content: props.info.posTime
    }, {
      label: '定位时间',
      content: props.info.gpsTime
    }, {
      label: '所属单位',
      content: props.info.holdname
    }, {
      label: '状态',
      content: props.info.deviceType != 'CARD' ? props.info.gender : '电量:' + props.info.remainBattery + '%',
      visable: props.info.deviceType != "CARD"
    }, {
      label: '速度',
      content: `${props.info.speed}`
    }, {
      label: '里程',
      content: `${props.info.mileage}`,
      visable: props.info.deviceType != "CARD"
    }, {
      label: '工作模式',
      content: refData.titleList[props.info.workMode],
      // content: `${props.info.workMode}${props.info.disturbStatus == 1?'+课堂模式':''}`,
      // title:refData.titleList[props.info.workMode],
      visable: props.info.deviceType && props.info.workMode > 0
    },
    // {
    //   label: '共存模式',
    //   content: props.info.disturbStatus == 1?'课堂模式':'关闭',
    //   title:props.info.disturbStatus == 1?'最多设置10个时段作为课堂模式时段,在课堂模式下设备为静音模式（按键和来电无声）':'',
    // },
    {
      label: '报警',
      contentSlot: 'alarm',
      content: props.info.alarmTypes.join(';'),
      visable: !!props.info.alarmTypes.length
    }, {
      label: '位置',
      contentSlot: 'address'
    }].filter(p => p.visible !== false));
    // 获取围栏列表
    const getFence = async imei => {
      refData.loading = true;
      const {
        msg,
        code,
        data
      } = await API.listCardRelation({
        imei
      });
      if (code === 0) {
        refData.fenceList = data;
      } else {
        refData.fenceList = [];
      }
      refData.loading = false;
    };
    const [debounceFence] = useDebounce(getFence, 300);
    // 全景街道
    function onPanoramaClick() {
      emit('action:onPanorama');
    }
    // 查看轨迹
    function onGJClick() {
      emit('action:gj');
    }
    // 查看详情
    function onMpttDetailClick() {
      emit('action:mpttDetail');
    }
    // 查看详情
    function onDetailClick() {
      emit('action:detail');
    }
    // 点名
    function onDMClick() {
      emit('action:dm');
    }
    // RFID号码下发
    function onRFClick() {
      emit('action:RF');
    }
    // 指令下发
    function onCmdDownClick() {
      emit('action:cmd');
    }
    function initActions() {
      refData.actions.push({
        label: '查看轨迹',
        iconName: 'icon-chakanguiji',
        showDeviceType: '',
        onClick: onGJClick
      });
      refData.actions.push({
        label: '查看详情',
        iconName: 'icon-chakanxiangqing1',
        showDeviceType: '',
        onClick: onDetailClick
      });
      refData.actions.push({
        label: '查看BMS详情',
        iconName: 'icon-chakanxiangqing1',
        showDeviceType: 'MPPT',
        onClick: onMpttDetailClick
      });
      refData.actions.push({
        label: '点名',
        showDeviceType: '',
        iconName: 'icon-dianming',
        onClick: onDMClick
      });
      if (hasAuth(1201))
        //拥有管理权限，可以发指令
        {
          refData.actions.push({
            label: '指令下发',
            iconName: 'icon-zhilingxiafa1',
            showDeviceType: '',
            onClick: onCmdDownClick
          });
        }
      // refData.actions.push({
      //   label: '街景',
      //   iconName: 'icon-weizhifuwu',
      //   onClick: onPanoramaClick,
      // });
    }
    initActions();
    return {
      ...toRefs(refData),
      contents,
      debounceFence
    };
  }
});