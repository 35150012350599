import { computed, defineComponent, onBeforeUnmount, onMounted, reactive, toRefs } from 'vue';
export default defineComponent({
  name: 'ScrollText',
  props: {
    speed: {
      type: Number,
      default: 100
    },
    content: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const refData = reactive({
      scrollBox: null,
      beginDiv: null,
      endDiv: null,
      showEnd: false,
      translateX: 0
    });
    const beginStyle = computed(() => ({
      transform: `translateX(-${refData.translateX}px)`
    }));
    let timerId = null;
    function autoScrollLeft() {
      stopScroll();
      let scroll_begin = refData.beginDiv;
      let scroll_div = refData.scrollBox;
      timerId = setInterval(() => {
        const clientWidth1 = scroll_begin.clientWidth;
        const clientWidth2 = scroll_div.clientWidth;
        if (clientWidth1 <= clientWidth2 || refData.translateX >= clientWidth1 - clientWidth2 / 3 * 2) {
          refData.translateX = 0;
          return;
        }
        refData.translateX++;
      }, props.speed || 100);
    }
    function stopScroll() {
      refData.showEnd = false;
      clearInterval(timerId);
      timerId = null;
    }
    onMounted(() => autoScrollLeft());
    onBeforeUnmount(() => stopScroll());
    return {
      ...toRefs(refData),
      beginStyle
    };
  }
});