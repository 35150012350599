import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-12b6b7f2"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  ref: "scrollBox",
  class: "scroll_div"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    ref: "beginDiv",
    style: _normalizeStyle(_ctx.beginStyle),
    class: "scroll_begin"
  }, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)], 4)], 512);
}